@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.spin {
	animation: spin 8s linear infinite;
}

.spin-on-scroll {
	animation: spin 1s linear infinite;
	animation-play-state: paused;
	animation-delay: calc(-1s * var(--scroll));

	animation-iteration-count: 1;
	animation-fill-mode: both;
}
