:root {
    /* Colors */
    --black: #000;
    --white: #fff;
    --primary: #148391;
    --secondary: #f5f5f593;
    --tertiary: #f5f5f5;

    /* typography */
    --dahlia: "Dahlia", serif;
    --nexa: "Nexa", sans-serif;
    --signature: "Signature";

    /* Gradient Colors */
    --color-primary: #08080a;
    --color-secondary: #0f0f0f;

    --mesh-primary: #163265;
    --mesh-secondary: #587ba9;
}