*,
*::before,
*::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	cursor: none;
}

html,
body {
	margin: 0;
	padding: 0;
	max-width: 100vw;
	overflow-x: hidden;
	background: #000317;
	color: #ffffff;
}
