@font-face {
	font-family: 'Dahlia';
	font-display: block;
	src: url('../../assets/fonts/dahlia-bold.otf') format('opentype');
	font-weight: 700;
}

@font-face {
	font-family: 'Dahlia';
	font-display: block;
	src: url('../../assets/fonts/dahlia-medium.otf') format('opentype');
	font-weight: 400;
}

@font-face {
	font-family: 'Dahlia';
	font-display: block;
	src: url('../../assets/fonts/dahlia-regular.otf') format('opentype');
	font-weight: 300;
}

@font-face {
	font-family: 'Nexa';
	font-display: block;
	src: url('../../assets/fonts/nexa-black.otf') format('opentype');
	font-weight: 900;
}

@font-face {
	font-family: 'Nexa';
	font-display: block;
	src: url('../../assets/fonts/nexa-bold.otf') format('opentype');
	font-weight: 700;
}

@font-face {
	font-family: 'Nexa';
	font-display: block;
	src: url('../../assets/fonts/nexa-regular.otf') format('opentype');
	font-weight: 400;
}

@font-face {
	font-family: 'Nexa';
	font-display: block;
	src: url('../../assets/fonts/nexa-light.otf') format('opentype');
	font-weight: 300;
}

@font-face {
	font-family: 'Nexa';
	font-display: block;
	src: url('../../assets/fonts/nexa-thin.otf') format('opentype');
	font-weight: 100;
}
