@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('./src/reset.css');
@import url('./src/typography.css');
@import url('./src/variables.css');
@import url('./src/cursor.css');
@import url('./src/animations.css');

.scroll-bar-hide {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.scroll-bar-hide::-webkit-scrollbar {
	display: none;
}

.bg-glass {
	background: #50505033;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	border: 1px solid rgba(80, 80, 80, 0.1);
}

.debug {
	border: 1px solid red !important;
}

.special-word {
	position: relative;
	white-space: nowrap;
}

.special-word:hover {
	z-index: 10000;
}

.special-word::before {
	content: '';
	position: absolute;
	bottom: 5px;
	height: 5px;
	width: 100%;
	background: var(--primary);
	z-index: -1;
	padding: 0 2px;
}

.special-word:hover::before {
	height: 22px;
	transition: height 0.3s ease-in-out;
}

.outline-text {
	-webkit-text-stroke: 1px var(--white);
	color: transparent;
}

.project-card h4 {
	transition: all 0.5s ease-in-out;
}

.text-shadow {
	text-shadow: 0px 2px 20px rgba(0, 0, 0, 0.5);
}

.project-card:hover img {
	filter: grayscale(0);
}

.project-card:hover h4 {
	z-index: 1000;
}

.image-container {
	overflow: hidden;
}

.project-card:hover .image-container img {
	transform: scale(1.05);
	filter: grayscale(0);
}

.image-container img {
	transition: all 0.2s ease-in-out;
}

/*container*/

.main-container {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}

@media screen and (min-width: 320px) {
	.main-container {
		max-width: 320px;
		padding: 0 0.5rem;
	}
}

@media screen and (min-width: 640px) {
	.main-container {
		max-width: 640px;
		padding: 0 1rem;
	}
}

@media screen and (min-width: 768px) {
	.main-container {
		max-width: 768px;
		padding: 0 1.5rem;
	}
}

@media screen and (min-width: 1024px) {
	.main-container {
		max-width: 1024px;
		width: 80%;
		min-width: 786px;
		padding: 0 2rem;
	}
}

@media screen and (min-width: 1280px) {
	.main-container {
		max-width: 1280px;
	}
}

@media screen and (min-width: 1536px) {
	.main-container {
		max-width: 2000px;
	}
}

@media screen and (min-width: 2000px) {
	.main-container {
		max-width: 2000px;
	}
}

.menu-btn {
	width: 50px;
	height: 50px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease-in-out;
}

.menu-btn__burger {
	width: 40px;
	height: 4px;
	background: #fff;
	transition: all 0.2s ease-in-out;
}

.menu-btn__burger::before,
.menu-btn__burger::after {
	content: '';
	position: absolute;
	width: 40px;
	height: 4px;
	background: #fff;
	transition: all 0.2s ease-in-out;
}

.menu-btn__burger::before {
	transform: translateY(-14px);
}

.menu-btn__burger::after {
	transform: translateY(14px);
	width: 30px;
}

.menu-btn.open .menu-btn__burger {
	transform: translateX(-50px);
	background: transparent;
}

.menu-btn.open .menu-btn__burger::before {
	transform: rotate(45deg) translate(35px, -35px);
}

.menu-btn.open .menu-btn__burger::after {
	transform: rotate(-45deg) translate(35px, 35px);
	width: 40px;
}

.menu-container {
	display: flex;
	justify-content: space-between;
	max-width: 1000px;
}

.link-number {
	opacity: 0;
}

.link-item:hover .link-number {
	opacity: 1;
	transform: translate(-32px, -16px);
	will-change: transform;
	transition: all 0.5s ease-in-out;
}

.nav-links:hover > :not(:hover) {
	opacity: 0.5;
	transition: all 0.5s ease-in-out;
}

body::before {
	position: fixed;
	content: '';
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: url('https://raw.githubusercontent.com/taimoorshahzada/Film-Grain-Noise-Effect-on-Background-in-Website-using-Pure-CSS/main/noise.gif');
	opacity: 0.03;
	z-index: 10000;
	pointer-events: none;
}

.btn {
	display: block;
	color: #ffffff;
	position: relative;
	letter-spacing: 1px;
	width: fit-content;
}

.btn__circle {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	border-radius: 100%;
	width: 100%;
	box-shadow: 0 0 1px 1px #fff;
	transition: 0.3s ease-in-out;
}

.btn__text {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	white-space: nowrap;
	z-index: 2;
	padding: 24px 8px;
	transition: 0.3s linear;
}

.btn__white-circle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) scale(0);
	border-radius: 100%;
	background: #ffffff;
	display: flex;
	transition: 0.3s ease-in-out;
}

.btn__white-circle svg {
	width: 24px;
	height: 24px;
	margin: auto;
}

.btn:hover .btn__circle {
	transform: scale(0);
}

.btn:hover .btn__white-circle {
	transform: translate(-50%, -50%) scale(1);
}

.btn:hover .btn__text {
	transform: translate(40px, -50%);
}

.form__group {
	position: relative;
	padding: 15px 0 0;
	margin-top: 10px;
	width: 100%;
}

.form__field {
	font-family: inherit;
	width: 100%;
	border: 0;
	border-bottom: 2px solid rgb(179, 179, 179);
	outline: 0;
	font-size: 1.3rem;
	color: white;
	padding: 7px 0;
	resize: none;
	background: transparent;
	transition: border-color 0.2s;
}

.form__field::placeholder {
	color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
	top: 20px;
}

.form__label {
	position: absolute;
	top: 0;
	display: block;
	transition: 0.2s;
	color: rgba(202, 202, 202, 0.693);
	pointer-events: none;
}

.form__field:focus ~ .form__label {
	position: absolute;
	top: 0;
	display: block;
	transition: 0.2s;
	font-size: 1rem;
	color: white;
	font-weight: 700;
}

.form__field:focus {
	padding-bottom: 6px;
	font-weight: 700;
	border-width: 3px;
	border-color: var(--primary);
}

.form__field:required {
	box-shadow: none;
}

.form__field:invalid {
	box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
	-webkit-text-fill-color: #ffffff;
	-webkit-box-shadow: 0 0 0px 40rem #000317 inset;
}

.gallery-item:hover .image-cover {
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.ruler {
	pointer-events: none;
	background: #fff;
	opacity: 0.5;
}