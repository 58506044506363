.cursor {
	position: absolute;
	border-radius: 100%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	display: flex;
	justify-content: center;
	align-items: center;
	mix-blend-mode: difference;
	z-index: 1000;
}

.outer-cursor {
	width: 50px;
	height: 50px;
	border: 2px solid white;
}

.inner-cursor {
	background-color: white;
	width: 5px;
	height: 5px;
}

.large {
	width: 20px;
	height: 20px;
}

.see-more {
	width: 140px;
	height: 140px;
}
